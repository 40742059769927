<template>
  <div class="chats">
    <div 
      v-for="(msgGrp, index) in chats"
      :key="`chats_${index}`" class="chat"
      :class="{ 'chat-left': !msgGrp.byUser }"
    >
      <div class="chat-avatar">
        <b-avatar 
          size="36"
          class="avatar-border-2 box-shadow-1"
          variant="transparent"
          :src="msgGrp.byUser ? userAvatar : aiAvatar" 
        />
      </div>
      <div class="chat-body">
        <div 
          v-for="(msgData, mk) in msgGrp.messages" 
          :key="`message_${mk}_${index}`" 
          class="chat-content table-responsive table-bordered table border-0"
        >
          <div :class="'float-left'">
            <p style="white-space: pre-wrap" v-html="msgData.msg"></p>
          </div>
          <div :class="'float-right'">
            <input type="text" :value="msgData.msg" :id="`_msg_${index}_${mk}`" style="display: inline-block;width:0px;height:0px; opacity: 0" />
            <b-img
              :id="`chat_${index}_${mk}`"
              :title="'Copy to Clipboard'"
              @click="copyToClipBoard(`_msg_${index}_${mk}`)"
              :src="require('@/assets/images/app-icons/icons8-copy-48.png')"
              width="24"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import { isEmpty } from '@/utilities'
import { showErrorNotification, showSuccessNotification } from '@/@core/comp-functions/ui/app'

export default {

  name: 'AiChatLog',

  components: {

  },

  props: {
    chats: Array,
    code: {
      type: Boolean,
      default: false,
      required: false
    }
  },

  data: () => ({
    userAvatar: null,
    aiAvatar: null
  }),

  computed: {
  },

  mounted() {
    this.aiAvatar = require('@/assets/images/app-icons/ai/ai-48.png')
    let userData = localStorage.getItem('userData')
    if(!isEmpty(userData)){
      userData = JSON.parse(userData)
      this.userAvatar = `${process.env.VUE_APP_API_BASE_END_POINT}/${userData.picture}`
    }
  },
  methods: {
    copyToClipBoard(copy_input_id) {
        let copyInput = document.querySelector('#'+copy_input_id);
        copyInput.select();
        try {
            if(document.execCommand('copy')){
                showSuccessNotification(this, 'Copied to clipboard');
            }else{
                showErrorNotification(this, "Failed to copy");
            }
        } catch (err) {
            showErrorNotification(this, "Failed to copy");
        }
        window.getSelection().removeAllRanges()
    },
  },
  watch: {
    chats: {
      handler(nv, ov){
        if(!isEmpty(nv)){
          const nvLastIndex = nv.length - 1
          const lastMsgGrp = nv.find((i,idx) => idx === nvLastIndex)
          if(!isEmpty(lastMsgGrp)){
            const lastMsgGrpTotalMsgs = lastMsgGrp.messages.length
            if(lastMsgGrpTotalMsgs > 0){
              setTimeout(() => {
                const refId = `chat_${nvLastIndex}_${lastMsgGrpTotalMsgs-1}`
                document.querySelector(`#${refId}`).scrollIntoView({ behavior: 'smooth' });
              }, 500);
            }
          }
        }
      }
    }
  }
}
</script>

<style scoped>
.chat-left{
  position: relative;
}
.chat{
  position: relative;
}
</style>

<style>
.cp_btn {
  cursor: pointer;
	position: relative;
	bottom: 0;
	right: -95%;
}
.cp_btn_right {
  cursor: pointer;
	position: absolute;
	bottom: 10px;
	right: 80px;
}


</style>